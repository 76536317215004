module.exports = {
  theme: {
    extend: {
      colors: {
        creamcan: "#F1BB5C", // yellow
        londonhue: "#C2A3C9", // purple
        nepal: "#90BDC2", // cyan blues
        //
        lightgrey: "#f5f5f5",
        mediumgrey: "#e5e5e5",
        predarkgrey: "#727272",
        darkgrey: "#424242",
      },
    },
    fontFamily: {
      sans: ["Montserrat", "system-ui", "-apple-system"],
      serif: ["Merriweather", "Georgia", "Cambria"],
    },
    typography: (theme) => ({
      default: {
        css: {
          h2: {
            color: theme("colors.darkgrey"),
          },
          blockquote: {
            color: theme("colors.darkgrey"),
            quotes: "none",
          },
          ul: {
            color: theme("colors.darkgrey"),
            backgroundColor: "none",
            listStyle: "none",
            "li::before": {
              color: theme("colors.darkgrey"),
              backgroundColor: theme("colors.darkgrey"),
              fontWeight: "bold",
            },
            strong: {
              color: theme("colors.darkgrey"),
            },
          },
          a: {
            textDecoration: "none",
            color: theme("colors.darkgrey"),
            "&:hover": {
              color: theme("colors.nepal"),
              textDecoration: "none",
            },
          },
          img: {
            borderRadius: "6px",
          },
          figcaption: {
            color: `${theme("colors.predarkgrey")} !important`,
            textAlign: "center",
          },
        },
      },
    }),
  },
  plugins: [
    require("@tailwindcss/line-clamp"),
    require("@tailwindcss/typography"),
    "gatsby-plugin-postcss",
  ],
  corePlugins: {
    preflight: true,
  },
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
  purge: [
    "./src/**/*.html",
    "./src/**/*.vue",
    "./src/**/*.jsx",
    "./src/**/*.js",
  ],
}
